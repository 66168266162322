<template>
    <v-sheet color="primary lighten-1" :height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
        <v-container fluid class="pt-12">
            <v-row align-content="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <v-card class="pb-4">
                        <v-toolbar dark flat color="primary">
                            <v-toolbar-title>{{ 'Reset password' | localize }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon color="white" v-on="on" to="/">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ 'close' | localize }}</span>
                            </v-tooltip>
                        </v-toolbar>

                        <v-card-text>
                            <v-form v-model="valid" ref="form" validation>
                                <v-text-field color="primary" :label="'Email' | localize" name="email"
                                    prepend-icon="mdi-account" type="text" v-model="email"
                                    :rules="[rules.email, rules.required]">
                                </v-text-field>
                            </v-form>
                        </v-card-text>

                        <v-card-actions class="mt-4">
                            <v-spacer></v-spacer>
                            <v-btn small outlined class="text-capitalize mb-4 mr-4" color="primary" 
                                @click="onSubmit"
                                :loading="loading" :disabled="!valid || loading">
                                {{ 'confirm' | localize }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>
<script>
import localizeFilter from '../filters/localize.filter'

export default {
    data() {
        return {
            valid: false,
            email: '',
            rules: {
                required: value => !!value || localizeFilter('required field'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || localizeFilter('invalid e-mail')
                }
            }
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
    },
    methods: {
        async onSubmit() {

            if (this.$refs.form.validate()) {
                try {
                    await this.$store.dispatch('resetPassword', this.email)
                    setTimeout(() => {
                        this.$router.replace('/signin')
                    }, 500)
                }
                catch (error) {
                    await this.$store.dispatch('setError', error.code);
                }
            }
        }
    }
}
</script>